<template>
	<span>
		<a v-if="link" :href="link">{{ link }}</a>
		<add-link
			v-else
			:link="item.participation_link"
			:start="item.start"
			:name="item.name"
			:medium="item.video_medium"
			:id="item.appointment_id"
		></add-link>
	</span>
</template>

<script>
import AddLink from "@c/project/AddLink";
export default {
	name: "LinkOrAddCell",
	props: ["item", "column"],
	computed: {
		link() {
			return this.item.participation_link;
		},
	},
	data: () => {
		return {
			linkText: null,
			types: {
				"google meet": "meet.google",
				zoom: "zoom",
				"ms teams": "teams",
				skype: "skype",
			},
			dialog: false,
		};
	},
	components: {
		AddLink,
	},
	methods: {},
};
</script>
